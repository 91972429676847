import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import t from '@jetshop/intl';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import {
  addToCartSuccess,
  useTrackProductAdd
} from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';
import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';
import { addToCart } from '../Cart/queries/addToCart.gql';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { StyledButton } from './StyledButton';
import { ReactComponent as Plus } from '../../svg/Plus.svg';
import { ReactComponent as Minus } from '../../svg/Minus.svg';
import { useFormikContext } from 'formik';
// import { useTracker } from '@jetshop/core/analytics/Analytics';

const AddToCartQtyButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .styled-button-wrapper {
    width: 100% !important;
    margin-left: 10px;
    font-size: ${theme.fontSizes.button};
    height: 100%;
    padding: 0;
  }
`;

export const AddToCartQtyButton = ({
  product,
  variant,
  productPage,
  outOfStockNotify,
  validationMessage,
  disabled
}) => {
  const [qty, setQty] = useState(1);
  const { cartId, setCartId } = useContext(CartIdContext);
  const articleNumber = variant?.articleNumber ?? product.articleNumber;
  const price = {
    price: variant?.price ?? product.price,
    previousPrice: variant?.previousPrice ?? product.previousPrice
  };
  const trackAddProductVariant = useTrackProductAdd(variant ?? product);

  const [trigger] = useNotification();
  const { values } = useFormikContext();

  const commentCreator = vals => {
    const totalCommentsArray = [];
    const commentsArray = Object.entries(vals?.comments);
    commentsArray.forEach(comment => {
      const key = comment?.[0];
      const value = comment?.[1];
      let commentObj = {};
      commentObj['name'] = key;
      commentObj['value'] = value;

      totalCommentsArray.push({ ...commentObj });
    });

    return totalCommentsArray;
  };

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: qty,
        comments: commentCreator(values)
      }
    },
    onCompleted: data => {
      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={qty}
          price={price}
        />,
        { type: 'add-to-cart' }
      );

      trackAddProductVariant(product, qty);

      addToCartSuccess({ cartId, setCartId })({ data });
    }
  });

  return (
    <>
      <AddToCartQtyButtonContainer className="add-to-cart-qty-button-container">
        <QtyAdjuster qty={qty} setQty={setQty}></QtyAdjuster>
        {productPage ? (
          <div className="button-wrapper">
            {!outOfStockNotify && (
              <StyledButton
                testid="add-to-cart"
                className={'RED'}
                clickCallback={() => {
                  add();
                }}
                disabled={disabled}
              >
                <>{validationMessage ? validationMessage : t('Add to cart')}</>
              </StyledButton>
            )}
          </div>
        ) : (
          <StyledButton className={'RED'} clickCallback={add}>
            <>{validationMessage ? validationMessage : t('Add to cart')}</>
          </StyledButton>
        )}
      </AddToCartQtyButtonContainer>
    </>
  );
};

const QtyAdjusterContainer = styled('div')`
  background: ${theme.colors.lightgrey};
  display: flex;
  align-items: center;
  max-width: 180px;

  button {
    width: 25%;
    height: 45px;
    background: ${theme.colors.lightgrey};
    font-size: 30px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  input {
    border: none;
    width: 50%;
    height: calc(100% - 4px);
    background: ${theme.colors.white};
    text-align: center;
    font-size: ${theme.fontSizes.largeParagraph};

    /* --> Remove standard buttons from input <-- */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }

    :focus {
      outline: none;
      border: 1px solid ${theme.colors.black};
    }
  }
`;

export const QtyAdjuster = ({ qty = 1, setQty }) => {
  return (
    <QtyAdjusterContainer className="qty-adjuster">
      <button
        disabled={qty < 2}
        onClick={e => {
          e.preventDefault();
          setQty(qty - 1);
        }}
      >
        <Minus />
      </button>
      <input
        value={qty}
        type={'number'}
        onChange={e => {
          const value = e?.currentTarget?.value;
          const newInt = parseInt(value);
          const notNumber = isNaN(newInt);

          if (!notNumber) {
            setQty(newInt);
          }
        }}
      />
      <button
        onClick={e => {
          e.preventDefault();
          setQty(qty + 1);
        }}
      >
        <Plus />
      </button>
    </QtyAdjusterContainer>
  );
};
