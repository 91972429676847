import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
import { ReactComponent as MenuIcon } from '../../../svg/Menu-hamburger.svg';
import { theme } from '../../Theme';
import IconRow from './IconRow';
import { SearchField } from '@jetshop/ui/Search';
import autocompleteQuery from './AutocompleteQuery.gql';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { IconItem } from './IconRow';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { HeaderCampaignBar } from './DesktopHeader';
import { B2bBanner } from './B2bBanner';
import useChannelCheck from '../../hooks/useChannelCheck';
import { useScrollPosition } from '../../hooks/HeaderHeightContext';
// import { useIntl } from '@jetshop/intl';

const MobileHeaderWrapper = styled('div')`
  background: ${theme.colors.white};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
  min-height: 85px;
`;

const LogoLink = styled(Link)`
  padding: 10px;

  svg {
    height: 18px;
    width: 150px;
  }
`;

const MobileTopWrapper = styled('div')`
  background: ${theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 2;
  padding: 0 15px;
  height: 60px;
  min-height: 100%;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.border};

  .wrapper {
    flex: 1;
  }

  .right {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .left {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 20px;
    }
  }
`;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  /* height: 58px; */
  will-change: height;
  transition: height 0.3s ease-out;
  &.active {
    height: 58px;
    input {
      height: 58px;
    }
  }
  &.inactive {
    height: 0;

    input {
      opacity: 0;
    }
  }
  &.search-active {
    height: 58px !important;
  }

  &.b2b {
    width: 100%;

    input {
      outline: none;
      margin-left: 0;
      width: 100%;
    }

    [data-flight-searchfield] {
      width: 90%;
      > div {
        width: 100%;
      }
    }

    [data-flight-searchfield-flyout] {
      padding: 20px;
    }

    [data-flight-searchfield-cancel] {
      display: none;
    }
    h2 {
      font-family: ${theme.fonts.primary};
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    li,
    a {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
      color: #333 !important;
      text-decoration: none;
      letter-spacing: 0.01em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
const InnerSearchFieldWrapper = styled('div')`
  background: ${theme.colors.lightgrey};
  width: 100%;
  height: 100%;
  display: flex;

  > div {
    width: 90%;
    display: flex;
    margin: auto;
    justify-content: center;
    button {
      display: none;
    }
  }

  input {
    border-radius: 4px;
    border: 1px solid #dadada;
    width: 100%;
    height: 41px;
    padding: 0 26px;
    :focus {
      outline: none;
    }
  }
`;

export default function MobileHeader({ searchOpen, setSearchOpen }) {
  const { hideTarget } = useContext(ModalContext);
  const { modals } = useContext(ModalContext);
  const topPosition = useHeaderHeight();

  // const translate = useIntl();
  // const searchPlaceholder = translate('Search for products');

  const scrollPosition = useScrollPosition() + 200;

  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (scrollPosition === 200) {
      setSearchOpen(false);
    }
  }, [scrollPosition]);

  setTimeout(() => {
    setShowSearch(true);
  }, 1000);

  const { isB2B } = useChannelCheck();
  return (
    <>
      <MobileHeaderWrapper id="site-header">
        {isB2B && <B2bBanner />}
        <MobileTopWrapper>
          <div className="left">
            <IconItem
              onClick={() => {
                // Closes search if open
                setSearchOpen(false);
              }}
            >
              <DrawerTrigger
                preventOverflow={true}
                id="menu-drawer"
                coverStyles={{ top: topPosition }}
              >
                {drawer => (
                  <button
                    data-testid="menu-button"
                    id="menu-button"
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <MenuIcon />
                  </button>
                )}
              </DrawerTrigger>
            </IconItem>

            <IconItem>
              <button
                data-testid="search-button"
                id="search-button"
                onClick={() => {
                  modals && modals[0]?.hideTarget();
                  setSearchOpen(!searchOpen);
                }}
              >
                <SearchIcon />
              </button>
            </IconItem>
          </div>
          <div className="wrapper">
            <LogoLink
              onClick={() => {
                // Closes flyout if open
                hideTarget();
              }}
              to="/"
            >
              <Logo />
            </LogoLink>
          </div>
          <div className="right">
            <IconRow searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
          </div>
        </MobileTopWrapper>
        {showSearch &&
          (isB2B ? (
            <SearchFieldWrapper
              className={cx(
                'b2b',
                scrollPosition < 200 && 'active',
                scrollPosition > 201 && 'inactive',
                searchOpen && 'search-active'
              )}
            >
              <InnerSearchFieldWrapper>
                <SearchField
                  onCancel={() => null}
                  focusOnLoad={false}
                  placeholder="Sök efter produkt"
                  autocompleteQuery={autocompleteQuery}
                  onSubmit={() => setSearchOpen(false)}
                  onClick={() => setSearchOpen(false)}
                />
              </InnerSearchFieldWrapper>
            </SearchFieldWrapper>
          ) : (
            <SearchFieldWrapper
              className={cx(
                scrollPosition < 200 && 'active',
                scrollPosition > 201 && 'inactive',
                searchOpen && 'search-active'
              )}
              id="hr-search"
            >
              <InnerSearchFieldWrapper>
                {/* <div>
                  <input placeholder={searchPlaceholder} />
                </div> */}
              </InnerSearchFieldWrapper>
            </SearchFieldWrapper>
          ))}
        <HeaderCampaignBar />
      </MobileHeaderWrapper>
    </>
  );
}
